export default {
  eco: {
    ltr: '//img.ltwebstatic.com/images3_ccc/2024/10/11/0a/1728633636b74cc0407adcacdc7d7c637b757f413a.png',
    rtl: '//img.ltwebstatic.com/images3_ccc/2024/10/11/62/172863365308cef6779fb255ce5c2d6c2c11675d15.png',
  },
  new: {
    ltr: '//img.ltwebstatic.com/images3_ccc/2024/10/11/2f/1728633698932d4a13e231fa6bbb1f93105c2f7f20.png',
    rtl: '//img.ltwebstatic.com/images3_ccc/2024/10/12/ce/172872572048577deeff9a92057f5cad73c2c8453d.png',
  },
  hot: {
    ltr: '//img.ltwebstatic.com/images3_ccc/2024/10/11/e2/17286335102d816c381b69a07d03dab7b0da595bbc.png',
    rtl: '//img.ltwebstatic.com/images3_ccc/2024/10/11/e2/172863351076c1f35077b0f7a34220ee1572de0f95.png',
  },
  thickening: {
    us: 'https://img.ltwebstatic.com/images3_ccc/2024/11/19/d6/1731997265e6c25e5178662f398d6924cc3049626e.png',
    ltr: 'https://img.ltwebstatic.com/images3_ccc/2024/11/19/85/1731997244b9a1e7415ad9ee473676473e3dd327b8.png',
    rtl: 'https://img.ltwebstatic.com/images3_ccc/2024/11/19/72/1731997226616729895ee93544eef5b381234b4128.png'
  }
}
